//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './VideoInteraction.module.css'
//React Hooks
import { useEffect, useState } from 'react';
//Dev Hooks
import { useLanguageValue } from '../context/LanguageContext';
import { useAskChatGPT } from '../hooks/useAskChatGPT';
import { useUpdateDocument } from '../hooks/useUpdateDocument';

const VideoInteraction = ({ originalLanguage, contentUntilInteraction, pointInteraction, userId, projectId, closeVideoInteraction, projectVideoInteraction }) => {

    const { t } = useTranslation(['projectpreview']);
    const { preferenceLanguage } = useLanguageValue();
    const { handleOpenAiApi, returnGPT, textTranslated, loading, errorGPT, generateGPT } = useAskChatGPT();
    const { updateDocument, responseUpdate } = useUpdateDocument("Domains", userId);

    const [interactionType, setInteractionType] = useState("summary");
    const [questionQ, setQuestionQ] = useState(1);
    const [choiceQ, setChoiceQ] = useState(4);
    const [qtdMaxQuestions, setQtdMaxQuestions] = useState(3);
    const [askingIA, setAskingIA] = useState(false);
    const [generatingInteraction, setGeneratingInteraction] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [error, setError] = useState("");
    const [AIHelp, setAIHelp] = useState(true);
    const [language, setLanguage] = useState("Portuguese");
    const [projectContent, setProjectContent] = useState(contentUntilInteraction);
    const [orderGPT, setOrderGPT] = useState("assessment");

    const [summary, setSummary] = useState("");
    const [questions, setQuestions] = useState([]);
    const [content, setContent] = useState("");
    const [applicationCase, setApplicationCase] = useState("");
    const [contentText, setContentText] = useState("");

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    const handleDirectionClick = () => {
        setMsgText(t('projectpreview:videointeraction.Gerando interação'));
        setAskingIA(true);
        handleAskGPT();
    }

    const changeQuestionQty = (questionqty) => {
        setQuestionQ(questionqty);
    }

    const changeChoicesQty = (choicesqty) => {
        setChoiceQ(choicesqty);
    }

    const handleAskGPT = async () => {
        window.scrollTo(0, 0);
        if (AIHelp) {
            setMsgText(t('projectpreview:asssessmentparameters.aguarde quizz sendo gerado'));
            setAskingIA(true);
            try {
                //showDivFloating(false)
                const res = await handleOpenAiApi({
                    subject: "",
                    selectedTopics: "",
                    questionQ,
                    choiceQ,
                    feedback: false,
                    level: "medium",
                    language,
                    orderGPT: orderGPT,
                    userInput: projectContent ? projectContent : "",
                    notConsider: ""
                });
            } catch (error) {
                //showDivFloating(true)
                setError(error);
                setAskingIA(false);
                closeVideoInteraction(false)
            }
        } else {
            //addFileManualQuestion(true)
        }
    }

    const updateProject = async () => {
        const newInteraction = {
            interaction_type: interactionType,
            content_text: contentText,
            interaction_time: pointInteraction,
            questions: questions,
            active: true
        };

        const updatedVideoInteractions = [...projectVideoInteraction, newInteraction];

        const projectInfos = {
            actionDoc: "update",
            domain: userId,
            id: projectId,
            video_interaction: updatedVideoInteractions
        };

        try {
            const res = await updateDocument(projectInfos);
            if (res.error) {
                setError(res.error);
            } else {
                setAskingIA(false);
                closeVideoInteraction(true, updatedVideoInteractions);
            }
        } catch (error) {
            setError("An error occurred while updating the project: " + error.message);
            console.log("An error occurred while updating the project: " + error.message)
            closeVideoInteraction(false);
        }
    };

    useEffect(() => {
        originalLanguage && setLanguage(originalLanguage);
    }, [originalLanguage])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    useEffect(() => {
        switch (interactionType) {
            case "summary":
                setOrderGPT("summary");
                break;
            case "question":
                setOrderGPT("assessment");
                break;
            case "content":
                setOrderGPT("supplementaryContent");
                break
            default:
                setOrderGPT("assessment");
        }
    }, [interactionType])

    useEffect(() => {
        if (returnGPT) {
        
            switch (interactionType) {
                case "summary":
                    setContentText(returnGPT);
                    break;
                case "quiz":
                    setQuestions(returnGPT);
                    break;
                case "supplementaryContent":
                    setContentText(returnGPT);
                    break
                case "case":
                    setContentText(returnGPT);
                    break
                default:
                    setContentText(returnGPT);
            }
        }
    }, [returnGPT])

    useEffect(() => {
        if (errorGPT) {
            setError(errorGPT);
            setAskingIA(false);
        }
    }, [errorGPT])

    useEffect(() => {
        if ((contentText && contentText.length > 0) || 
            (questions && questions.length > 0)) {
            updateProject();
        }
    }, [contentText, questions]);

    return (
        <div>
            {askingIA ? (
                <div className='d-flex align-items-center justify-content-center flex-column'>
                    <div className="col-6 text-center mt-5">
                        <div className="spinner-grow text-primary" role="status"></div>
                        <h5 className="mt-3">
                            {`${msgText}`}
                        </h5>
                    </div>
                </div>
            ) : (
                <div className="p-0 m-0">
                    <h6 className={`m-3`}>{t('projectpreview:videointeraction.indique abaixo os parâmetros para a interação')}</h6>
                    <div className="pt-2 ms-3">
                        <div className="row align-items-center">
                            <label><h6>{t('projectpreview:videointeraction.Que tipo de interação deseja criar')}</h6></label>

                            <div>
                                <div className="form-check mt-1">
                                    <input
                                        className={`form-check-input ${styles.option_check}`}
                                        type="radio"
                                        name="meu_radio"
                                        value=""
                                        checked={interactionType === "summary"}
                                        onChange={() => setInteractionType("summary")}
                                    />
                                    <label className="form-check-label"><h6>{t('projectpreview:videointeraction.Resumo')}</h6></label>
                                </div>
                            </div>
                            <div>
                                <div className="form-check mt-1">
                                    <input
                                        className={`form-check-input ${styles.option_check}`}
                                        type="radio"
                                        name="meu_radio"
                                        value="text"
                                        checked={interactionType === "quiz"}
                                        onChange={() => setInteractionType("quiz")}
                                    />
                                    <label className="form-check-label"><h6>{t('projectpreview:videointeraction.Quiz')}</h6></label>
                                </div>
                            </div>
                            {interactionType === "quiz" &&
                                <div className={`mb-4`}>
                                    <div className="row mt-4">
                                        <h6 className="col-12">{t('projectpreview:asssessmentparameters.quantidade de questões')} </h6>
                                        <div className={`col-12 ${styles.qty_question}`}>
                                            {questionQ}
                                            <input
                                                className={`ms-3 ${styles.range_customize}`}
                                                type="range"
                                                min="1"
                                                max={qtdMaxQuestions}
                                                required
                                                name="qtyQuestion"
                                                value={questionQ}
                                                onChange={(e) => changeQuestionQty(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <p></p>

                                    <div className="row d-flex align-items-center mt-4">
                                        <h6 className="col-12">{t('projectpreview:asssessmentparameters.quantidade de alternativas')} </h6>
                                        <div className="col-12">
                                            <select
                                                className={styles.form_parameters}
                                                required
                                                name="qtyChoice"
                                                value={choiceQ}
                                                onChange={(e) => changeChoicesQty(e.target.value)}>
                                                <option value="">{`Selecione uma opção`}</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>}
                                <div>
                                <div className="form-check mt-1">
                                    <input
                                        className={`form-check-input ${styles.option_check}`}
                                        type="radio"
                                        name="meu_radio"
                                        value=""
                                        checked={interactionType === "supplementaryContent"}
                                        onChange={() => setInteractionType("supplementaryContent")}
                                    />
                                    <label className="form-check-label"><h6>{t('projectpreview:videointeraction.Conteúdo')}</h6></label>
                                </div>
                            </div>
                            <div>
                                <div className="form-check mt-1">
                                    <input
                                        className={`form-check-input ${styles.option_check}`}
                                        type="radio"
                                        name="meu_radio"
                                        value=""
                                        checked={interactionType === "case"}
                                        onChange={() => setInteractionType("case")}
                                    />
                                    <label className="form-check-label"><h6>{t('projectpreview:videointeraction.Case')}</h6></label>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div>
                        <button className={`mt-5 ms-3 mb-4 ${styles.button_quizz} ${styles.btn_style}`} onClick={() => handleDirectionClick()}>
                            {`${t('projectpreview:videointeraction.Gerar interação')}`}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VideoInteraction;