
import styles from './Faq.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import React, { useEffect, useRef, useState } from 'react';
//Dev hooks
import { useNavigate } from 'react-router-dom';
import { useAuthValue } from '../../context/AuthContext';
import { useLanguageValue } from '../../context/LanguageContext';
//Translation
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

const Faq = ({ userSetLanguage }) => {
    const fadeInElements = useRef([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [formClass, setFormClass] = useState("");

    /* --- INICIO LINGUAGEM --- */
    const { t } = useTranslation(['faq']);
    const { user } = useAuthValue();
    const { preferenceLanguage } = useLanguageValue();
    const [preferenceBrowserLanguage, setPreferenceBrowserLanguage] = useState();

    const navigate = useNavigate();

    const [userLanguage, setUserLanguage] = useState(userSetLanguage ? userSetLanguage : navigator.language);

    const goRegister = () => {
        window.scrollTo(0, 0);
        navigate("/register")
    }

    useEffect(() => {
        if (userSetLanguage) {
            setUserLanguage(userSetLanguage)
        }
    }, [userSetLanguage])

    useEffect(() => {
        switch (userLanguage) {
            case "pt-BR":
            case "pt":
                setPreferenceBrowserLanguage("Português");
                break;
            case "en-US":
            case "en":
                setPreferenceBrowserLanguage("Inglês");
                break;
            case "es-ES":
            case "es":
                setPreferenceBrowserLanguage("Espanhol");
                break;
            default:
                setPreferenceBrowserLanguage("Inglês");
                break;
        }
    }, [userLanguage]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (preferenceBrowserLanguage) {
            var language = "en";
            switch (preferenceBrowserLanguage) {
                case "Português":
                    language = "pt";
                    break;
                case "Espanhol":
                    language = "es";
                    break;
                case "Inglês":
                    language = "en";
                    break;
                default:
                    language = "en";
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceBrowserLanguage])

    useEffect(() => {
        user && preferenceLanguage && setPreferenceBrowserLanguage(preferenceLanguage)
    }, [preferenceLanguage, user])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    return (
        <div>
            <div className={`row ${styles.titulo}`}>{t('faq:home.Perguntas Frequentes')}</div>
            <div className="accordion accordion-flush" id="accordionFlushExample">
                {/* FAQ #1 */}
                <div className="accordion-item ms-3 me-3">
                    <h2 className="accordion-header">
                        <button className={`accordion-button collapsed ${styles.askedQuestion}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            {t('faq:home.question1')}
                        </button>
                    </h2>
                    <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">{t('faq:home.answer1')}
                        </div>
                    </div>
                </div>
                {/* FAQ #2 */}
                <div className={`accordion-item ms-3 me-3 ${styles.accordion}`}>
                    <h2 className="accordion-header">
                        <button className={`accordion-button collapsed ${styles.askedQuestion}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            {t('faq:home.question2')}
                        </button>
                    </h2>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">{t('faq:home.answer2')}
                        </div>
                    </div>
                </div>
                {/* FAQ #3 */}
                <div className="accordion-item ms-3 me-3">
                    <h2 className="accordion-header">
                        <button className={`accordion-button collapsed ${styles.askedQuestion}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            {t('faq:home.question3')}
                        </button>
                    </h2>
                    <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">{t('faq:home.answer3')}
                        </div>
                    </div>
                </div>
                {/* FAQ #4 */}
                <div className="accordion-item ms-3 me-3">
                    <h2 className="accordion-header">
                        <button className={`accordion-button collapsed ${styles.askedQuestion}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                            {t('faq:home.question4')}
                        </button>
                    </h2>
                    <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">{t('faq:home.answer4')}
                        </div>
                    </div>
                </div>
                {/* FAQ #5 */}
                <div className="accordion-item ms-3 me-3">
                    <h2 className="accordion-header">
                        <button className={`accordion-button collapsed ${styles.askedQuestion}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                            {t('faq:home.question5')}
                        </button>
                    </h2>
                    <div id="flush-collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">{t('faq:home.answer5')}
                        </div>
                    </div>
                </div>
                {/* FAQ #6 */}
                <div className="accordion-item ms-3 me-3">
                    <h2 className="accordion-header">
                        <button className={`accordion-button collapsed ${styles.askedQuestion}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                            {t('faq:home.question6')}
                        </button>
                    </h2>
                    <div id="flush-collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">{t('faq:home.answer6')}
                        </div>
                    </div>
                </div>
                {/* FAQ #7 */}
                <div className="accordion-item ms-3 me-3">
                    <h2 className="accordion-header">
                        <button className={`accordion-button collapsed ${styles.askedQuestion}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                            {t('faq:home.question7')}
                        </button>
                    </h2>
                    <div id="flush-collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">{t('faq:home.answer7')}
                        </div>
                    </div>
                </div>
                {/* FAQ #8 */}
                <div className="accordion-item ms-3 me-3 mb-3">
                    <h2 className="accordion-header">
                        <button className={`accordion-button collapsed ${styles.askedQuestion}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                            {t('faq:home.question8')}
                        </button>
                    </h2>
                    <div id="flush-collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">{t('faq:home.answer8')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq;