import { db } from '../firebase/config';
import { useInsertDocument } from './useInsertDocument';
import { useCheckCoupon } from './useCheckCoupon';
import { Timestamp } from 'firebase/firestore';

export const useCreateUserDomain = () => {
    let userID = "";

    const { insertDocument, response } = useInsertDocument("Domains", userID);
    const {checkCouponisValid} = useCheckCoupon();

    const createUserDomain = async (userid, email, plan, couponCode, planCoupon) => {

        const {messageCoupon, validDays, monthlyProjectLimit, validCoupon} = checkCouponisValid(planCoupon, couponCode);

        userID = userid;

        const creationDate = Timestamp.now();
        const date_expired = new Date(creationDate.toDate());
        date_expired.setDate(date_expired.getDate() + validDays);
        const timestamp_expired = Timestamp.fromDate(date_expired);

        const newUser = {
            cc_operator: "",
            coupon: validCoupon ? couponCode : "",
            data_storage_used: 0,
            data_storage_limit: 200,
            domain: userID,
            domain_active: true,
            email: email,
            expired_trial_date: timestamp_expired,
            invited_admin_1: "",
            invited_admin_2: "",
            monthly_project_limit: monthlyProjectLimit,
            number_cc: "",
            origin: "",
            owner: userID,
            plan: plan,
            ppt_project_limit: 1,
            ppt_storage_size: 0,
            signature_date: creationDate,
            status: plan === "free" ? plan : "trial",
            token_admin_1: "",
            token_admin_2: "",
            video_project_limit: 1,
            video_storage_size: 0,
        }

        await insertDocument(newUser);

    }

    return { createUserDomain };

}