//React Hooks
import { useState, useEffect } from 'react';
//Dev Hooks
import { useAuthValue } from '../context/AuthContext';
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import { useLanguageValue } from '../context/LanguageContext';


export const useConvertPPTtoImage = () => {

    const { user } = useAuthValue();

    const [errorConvertingPptImage, setErrorConvertingPptImage] = useState("");
    const [userId, setUserId] = useState(user.uid);
    const [idProject, setIdProject] = useState(null);
    const [pptGenerated, setPptGenerated] = useState(false);
    const [pptSize, setPptSize] = useState(0);

    const { preferenceLanguage } = useLanguageValue();
    const { t } = useTranslation(['choosefile']);

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    const convertPPt = async (url) => {

        let pptxPath = "";
        const regex = /\/o\/(.*?)\?alt=media/;
        const matches = url.match(regex);

        if (matches && matches.length > 1) {
            pptxPath = matches[1];
        } else {
            setErrorConvertingPptImage(t('choosefile:messages.Não foi possível extrair o caminho relativo'));
        }
        try {
            const response = await fetch(`https://us-central1-quizzai-4b3cd.cloudfunctions.net/convertPPTtoImageQueue?userId=${userId}&projectId=${idProject}&pptxPath=${pptxPath}`, {
                method: 'GET',
            });

            if (response.status === 200) {
                const { totalSize } = await response.json();
                const fileSizeInKB = totalSize / 1024;
                const fileSizeInMB = fileSizeInKB / 1024;
                setPptSize(fileSizeInMB);
                setPptGenerated(true);
            } else {
                setErrorConvertingPptImage(t('choosefile:messages.Erro na conversão das imagens do PPT'));
            }
        } catch (error) {
            setErrorConvertingPptImage(t('choosefile:messages.Erro na solicitação'));
        }
    }
    return { convertPPt, pptSize, errorConvertingPptImage }
}