// Firebase
import { onAuthStateChanged } from 'firebase/auth';
import { db } from './firebase/config';
import { doc, onSnapshot } from "firebase/firestore";

// ReactRouter
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// Estilos
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// Context
import { AuthProvider } from './context/AuthContext';
import { LanguageProvider } from './context/LanguageContext';
import './i18n';

// Hooks
import { useState, useEffect, Suspense } from 'react';
import { useAuthentication } from './hooks/useAuthentications';

// Pages
import Home from './pages/home/HomeNova';
import MyProjects from './pages/home/MyProjects.js';
import About from './pages/about/About';
import Register from './pages/register/Register';
import Login from './pages/login/Login';
import Catalog from './pages/catalog/Catalog';
import MyAccount from './pages/perfil/MyAccount';
import CreateAssessment from './pages/createassessment/CreateAssessment';
import UpdateAssessment from './pages/assessment/UpdateAssessment';
import Faq from './pages/faq/Faq';
import Plans from './pages/plans/Plans.js';
import Features from './pages/features/Features';
import Homepage from './pages/homepage/Homepage';
import Help from './pages/help/Help';
import Gdpr from './pages/gdpr/Gdpr';
import Privacy from './pages/privacy/Privacy';
import Terms from './pages/terms/Terms';
import DataProcessingAgreement from './pages/dpa/DataProcessingAgreement';
import Contact from './pages/contact/Contact';

// Components
import Navbar from './components/Navbar';
import Footer from './components/Footer';

// i18next
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

function App() {
  const [user, setUser] = useState(undefined);
  const [userId, setUserId] = useState("");
  const [preferenceLanguage, setPreferenceLanguage] = useState("Português");
  const [userSetLanguage, setUserSetLanguage] = useState("");
  const [hideItem, setHideItem] = useState("");

  const { auth } = useAuthentication();

  const hideMenuItem = (item) => {
    setHideItem(item);
  };

  const changeSiteLanguage = (lang) => {
    if (lang === "Português") {
      setUserSetLanguage("pt");
    } else if (lang === "Espanhol") {
      setUserSetLanguage("es");
    } else {
      setUserSetLanguage("en");
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        setUserId(auth.currentUser.uid);
      }
    });
  }, [auth]);

  useEffect(() => {
    if (userId) {
      const docRef = doc(db, "Users", userId);
      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          setPreferenceLanguage(docSnap.data().language);
        }
      });
      return () => unsubscribe();
    }
  }, [userId]);

  return (
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<div>Loading...</div>}>
        <div className="App p-0 m-0">
          <AuthProvider value={{ user }}>
            <LanguageProvider value={{ preferenceLanguage }}>
              <BrowserRouter>
                <Navbar
                  changeSiteLanguage={changeSiteLanguage}
                  hideItem={hideItem}
                />
                <div className="container m-0 p-0">
                  <Routes>
                    <Route path='/' element={user ? <Home userSetLanguage={userSetLanguage} hideMenuItem={hideMenuItem} userId={user.uid}/> : <Navigate to="/home" />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/login' element={!user ? <Login userSetLanguage={userSetLanguage} /> : <Navigate to="/" />} />
                    <Route path='/home' element={!user ? <Homepage userSetLanguage={userSetLanguage} hideMenuItem={hideMenuItem} userId={userId}/> : <Navigate to="/" />} />
                    <Route path='/register' element={!user ? <Register userSetLanguage={userSetLanguage} /> : <Navigate to="/" />} />
                    <Route path='/assessment/create' element={user ? <CreateAssessment /> : <Navigate to="/login" />} />
                    <Route path='/assessment' element={user ? <UpdateAssessment /> : <Navigate to="/login" />} />
                    <Route path='/seuperfil' element={user ? <MyAccount hideMenuItem={hideMenuItem}/> : <Navigate to="/login" />} />
                    <Route path='/homepage' element={<Homepage userSetLanguage={userSetLanguage} />} />
                    <Route path='/myprojects' element={user ? <MyProjects userSetLanguage={userSetLanguage} hideMenuItem={hideMenuItem}/> : <Navigate to="/home"/>}  />
                    <Route path='/features' element={<Features userSetLanguage={userSetLanguage} />} />
                    <Route path='/faq' element={<Faq userSetLanguage={userSetLanguage} />} />
                    <Route path='/catalog' element={<Plans userSetLanguage={userSetLanguage} />} />
                    <Route path='/help' element={<Help userSetLanguage={userSetLanguage} hideMenuItem={hideMenuItem}/>} />
                    <Route path='/gdpr' element={<Gdpr userSetLanguage={userSetLanguage} />} />
                    <Route path='/privacy' element={<Privacy userSetLanguage={userSetLanguage} />} />
                    <Route path='/terms' element={<Terms userSetLanguage={userSetLanguage} />} />
                    <Route path='/dpa' element={<DataProcessingAgreement userSetLanguage={userSetLanguage} />} />
                    <Route path='/contact' element={<Contact userSetLanguage={userSetLanguage} />} />
                  </Routes>
                </div>
                <Footer userSetLanguage={userSetLanguage} />
              </BrowserRouter>
            </LanguageProvider>
          </AuthProvider>
        </div>
      </Suspense>
    </I18nextProvider>
  );
}

export default App;
