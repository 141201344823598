
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
//Dev Hooks
import { useLanguageValue } from '../context/LanguageContext';
import styles from './AssessmentTopics.module.css';
import { useState, useEffect } from 'react';

const AssessmentTopics = ({ topics, handleSetTopics, topicText }) => {
  const { t } = useTranslation(['projectpreview']);
  const { preferenceLanguage } = useLanguageValue();

  const [selectedItems, setSelectedItems] = useState([]);
  const [writeTopic, setWriteTopic] = useState(false);
  const [topicList, setTopicList] = useState();
  const [newTopic, setNewTopic] = useState("");

  const handleChange = (topic) => {
    if (selectedItems.includes(topic)) {
      setSelectedItems(selectedItems.filter(item => item !== topic));
    } else {
      setSelectedItems([...selectedItems, topic]);
    }
    handleSetTopics(topic);
  }

  const handleAddTopic = () => {
    setWriteTopic(true)
  }

  const handleHideInsertTopic = () => {
    setWriteTopic(false)
  }

  const insertTopic = () => {
    const newTopicList = [...topicList, newTopic];
    setTopicList(newTopicList)
    setWriteTopic(false)
    setNewTopic("")
  }

  useEffect(() => {
    const newTopicList = topics.map(topic => topic.replace(/^\[\d+\]\s*|\d+\.\s*|\[\s*|\s*\]$/g, ''));
    setTopicList(newTopicList)
  }, [])

  useEffect(() => {
    if (preferenceLanguage) {
      var language = "en";
      switch (preferenceLanguage) {
        case "Português":
          language = "pt"
          break;
        case "Espanhol":
          language = "es"
          break;
        case "Inglês":
          language = "en"
          break;
      }
      changeLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [preferenceLanguage])

  const changeLanguage = (language) => {
    localStorage.setItem('preferenceLanguage', language);
    i18n.changeLanguage(language);
  };

  return (
    <div >
      <h4 className={`mb-3 ${styles.texts_format}`}>{topicText} </h4>
      {topicList && topicList.map((topic, i) => (
        <div>
          <div className="form-check ms-1 me-1">
            <input
              className={`form-check-input ${styles.option_check}`}
              type="checkbox"
              value=""
              checked={selectedItems.includes(topic)}
              onChange={() => handleChange(topic)}
              id="flexCheckDefault" />
            <label className="form-check-label ms-1" for="flexCheckDefault">
              <h6>{topic}</h6>
            </label>
          </div>
          {topicList.length === i + 1 && writeTopic && (
            <div className="row">
              <div className="d-flex align-items-center ">
                <div className={`ps-2 `}>
                  <form>
                    <label>
                      <input
                        className={styles.form_field}
                        type="text"
                        required
                        name="projectName"
                        placeholder={t('projectpreview:assessmenttopic.Digite o novo tópico')}
                        value={newTopic}
                        onChange={(e) => setNewTopic(e.target.value)}
                      />
                    </label>
                  </form>
                </div>
                <div className=''>
                  <button className={`${styles.closeTopic}`}><i className={`bi bi-x-lg`} onClick={handleHideInsertTopic}></i></button>
                  <button className={`${styles.createTopic}`}><i className={`bi bi-check-lg `} onClick={insertTopic}></i></button>
                </div>
              </div>
            </div>
          )}
          {topicList.length === i + 1 && topicList.length < 12 && (
            <div className={styles.btn_plus} onClick={handleAddTopic}>
              <div className={`mt-3 ${styles.icon_plus}`}>
                <i className="bi bi-plus-circle"></i>
              </div>
            </div>
          )}
        </div>
      ))}


    </div>
  );
}

export default AssessmentTopics;
