import React, { useEffect, useState, useRef } from 'react';
import styles from './Homepage.module.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useNavigate } from 'react-router-dom';
import { useAuthValue } from '../../context/AuthContext';
import { useLanguageValue } from '../../context/LanguageContext';
import { useAuthentication } from '../../hooks/useAuthentications';
//Dev Components
import DialogBox from '../../components/DialogBox';
//Translation
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
//Images
import video01 from '../../videos/video1.mp4';

const Homepage = ({ userSetLanguage }) => {
  const [message, setMessage] = useState("");
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [action, setAction] = useState("");

  const { user } = useAuthValue();
  const { t } = useTranslation(['homepage']);
  const { preferenceLanguage } = useLanguageValue();
  const { login, error: authError, loading, loginWithGoogle } = useAuthentication(userSetLanguage);

  const [preferenceBrowserLanguage, setPreferenceBrowserLanguage] = useState("");

  const navigate = useNavigate();

  const [userLanguage, setUserLanguage] = useState(userSetLanguage ? userSetLanguage : navigator.language);

  const loginGoogle = async () => {
    const res = await loginWithGoogle();
  }

  const actionCloseDialogBox = (f, option) => {
    setAction("");
    setOpenDialogBox(false);
  }

  useEffect(() => {
    switch (userLanguage) {
      case "pt-BR":
      case "pt":
        setPreferenceBrowserLanguage("Português");
        break;
      case "en-US":
      case "en":
        setPreferenceBrowserLanguage("Inglês");
        break;
      case "es-ES":
      case "es":
        setPreferenceBrowserLanguage("Espanhol");
        break;
      default:
        setPreferenceBrowserLanguage("Inglês");
        break;
    }
  }, [userLanguage]);

  useEffect(() => {
    if (preferenceBrowserLanguage) {
      var language = "en";
      switch (preferenceBrowserLanguage) {
        case "Português":
          language = "pt";
          break;
        case "Espanhol":
          language = "es";
          break;
        case "Inglês":
          language = "en";
          break;
        default:
          language = "en";
      }
      changeLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [preferenceBrowserLanguage])

  useEffect(() => {
    if (authError) {
      setMessage(authError)
      setOpenDialogBox(true)
    }
  }, [authError])

  const changeLanguage = (language) => {
    localStorage.setItem('preferenceLanguage', language);
    i18n.changeLanguage(language);
  };

  /* --- FIM --- forçar para que todas as divs .card tenham a mesma altura */

  return (
    <div className={`row ${styles.row_items}`}>

      <div className={`col-md-12 ${styles.video}`}>
        <video autoPlay muted loop id="myVideo" className={styles.full_width_video} preload="auto">
          <source src={video01} type="video/mp4" />
        </video>
      </div>
      
      <div className="mt-3 mb-3">
        {!loading && <button onClick={() => loginGoogle()} className={`mt-3 ${styles.btn_google}`}><i className="bi bi-google me-3"></i> {t('homepage:home.login com google')}
        </button>}
      </div>
      {openDialogBox && <DialogBox
        action={action}
        actionScript={actionCloseDialogBox}
        isopen={true}
        questionDialog={message}
        optionsDialog={["Ok"]}
        userSetLanguage={userSetLanguage}
      />}
    </div>
  )
}

export default Homepage