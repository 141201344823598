
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, updateProfile, signOut } from 'firebase/auth';
import { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useCreateUserDoc } from '../hooks/useCreateUserDoc';
import { useCreateUserDomain } from '../hooks/useCreateUserDomain';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';


export const useAuthentication = (userSetLanguage) => {

    const { t } = useTranslation(['login']);

    const [error, setError] = useState();
    const [loading, setLoading] = useState();
    const [cancelled, setCancelled] = useState();
    const [preferenceBrowserLanguage, setPreferenceBrowserLanguage] = useState("");
    const [pageTexts, setPageTexts] = useState([]);

    const [userLanguage, setUserLanguage] = useState(userSetLanguage ? userSetLanguage : navigator.language);
    const { createUserDoc } = useCreateUserDoc();
    const { createUserDomain } = useCreateUserDomain();

    const auth = getAuth();
    const googleAuthProvider = new GoogleAuthProvider();

    function checkIfIsCancelled() {
        if (cancelled) {
            return;
        }
    }

    const signInWithGoogle = async () => {
        checkIfIsCancelled();

        setLoading(true);
        setError(null);

        try {
            const result = await signInWithPopup(auth, googleAuthProvider);
            const user = result.user;
            setLoading(false);

            await updateProfile(user, {
                displayName: user.displayName || 'Nome de usuário padrão',
            });
        } catch (error) {
            let systemErrorMessage;
            if (error.message.includes("Password")) {
                systemErrorMessage = pageTexts[2];
            } else if (error.message.includes("email-already")) {
                systemErrorMessage = pageTexts[3];
            } else {
                systemErrorMessage = pageTexts[4];
            }
            setError(systemErrorMessage);
            setLoading(false);
        }
    };

    const loginWithGoogle = async () => {
        checkIfIsCancelled();

        setLoading(true);
        setError(null);

        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            checkAndCreateUserDocs(user)
            setLoading(false);

        } catch (error) {
            let systemErrorMessage;

            if (error.message.includes("user-not-found")) {
                systemErrorMessage = pageTexts[0];
            } else if (error.message.includes("wrong-password")) {
                systemErrorMessage = pageTexts[1];
            } else {
                systemErrorMessage = pageTexts[5];
            }

            setLoading(false);
            setError(systemErrorMessage);
        }
    }

    const createUser = async (data) => {
        checkIfIsCancelled();
        setLoading(true);
        setError(null);

        try {
            const { user } = await createUserWithEmailAndPassword(
                auth,
                data.email,
                data.password,
            )
            await updateProfile(user, {
                displayName: data.displayName
            });
            setLoading(false);
        } catch (error) {
            let systemErrorMessage;
            if (error.message.includes("Password")) {
                systemErrorMessage = pageTexts[2];
            } else if (error.message.includes("email-already")) {
                systemErrorMessage = pageTexts[3];
            } else {
                systemErrorMessage = pageTexts[4];
            }
            setError(systemErrorMessage);
            setLoading(false);
        }
    };

    //logout
    const logout = () => {
        checkIfIsCancelled();
        signOut(auth);
    }

    //login
    const login = async (data) => {
        checkIfIsCancelled();

        setLoading(true);
        setError("");

        try {
            await signInWithEmailAndPassword(auth, data.email, data.password);
            setLoading(false);
        } catch (error) {
            let systemErrorMessage;

            if (error.message.includes("user-not-found")) {
                systemErrorMessage = t(`login:auth.Usuário não encontrado`);
            } else if (error.message.includes("wrong-password")) {
                systemErrorMessage = t(`login:auth.Senha incorreta. Digite novamente`);
            } else {
                systemErrorMessage = t(`login:auth.Erro ao logar"`);
            }

            setLoading(false);
            setError(systemErrorMessage);
        }
    }

    const checkAndCreateUserDocs = async (user) => {
        const id = user.uid
        const userRef = `Domains/${id}`
        const docRef = doc(getFirestore(), userRef)
        const docSnap = await getDoc(docRef)
        if (!docSnap.exists()) {
            createUserDocs(user)
        } 
    }

    const createUserDocs = async (user) => {
        const userID = user.uid
        const name = user.displayName
        const email = user.email
        const language = preferenceBrowserLanguage
        const planRegister = "trial-15"

        const userInfos = {
            email: email,
            displayName: name,
            company: "",
            jobTitle: "",
            country: "",
            language: language,
            userID,
            plan: planRegister,
        }
        const resDoc = createUserDoc(userInfos);
        const resUserDomain = createUserDomain(userID, email, planRegister, "", planRegister);
    }

    useEffect(() => {
        if (userSetLanguage) {
            setUserLanguage(userSetLanguage)
        }
    }, [userSetLanguage])

    useEffect(() => {
        switch (userLanguage) {
            case "pt-BR":
            case "pt":
                setPreferenceBrowserLanguage("Português");
                break;
            case "en-US":
            case "en":
                setPreferenceBrowserLanguage("Inglês");
                break;
            case "es-ES":
            case "es":
                setPreferenceBrowserLanguage("Espanhol");
                break;
            default:
                setPreferenceBrowserLanguage("Inglês");
                break;
        }
    }, [userLanguage]);

    useEffect(() => {
        return () => setCancelled(true);
    }, []);

    useEffect(() => {
        if (preferenceBrowserLanguage) {
            var language = "en";
            switch (preferenceBrowserLanguage) {
                case "Português":
                    language = "pt";
                    break;
                case "Espanhol":
                    language = "es";
                    break;
                case "Inglês":
                    language = "en";
                    break;
                default:
                    language = "en";
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceBrowserLanguage])
    
    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    return {
        auth,
        createUser,
        error,
        loading,
        logout,
        login,
        signInWithGoogle,
        loginWithGoogle
    }
};
