//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
//Styles
import styles from './ChooseFile.module.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
//Firebase
import { Timestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable, deleteObject, listAll, getMetadata } from "firebase/storage";
import { storage } from "../firebase/config";
import { useAuthValue } from '../context/AuthContext';
//React Hooks
import { useEffect, useState, useRef } from 'react';
import { franc } from 'franc';
import ISO6391 from 'iso-639-1';
//Dev Hooks
import { useConvertPPTtoImage } from '../hooks/useConvertPPTtoImages';
import { useConvertPPTtoText } from '../hooks/useConvertPPTtoText';
import { useCreateProject } from '../hooks/useCreateProject';
import { useConvertVideoToText } from '../hooks/useConvertVideoToText';
import { useLanguageValue } from '../context/LanguageContext';
import { useAskChatGPT } from '../hooks/useAskChatGPT';
import { useCheckLicenseLimits } from '../hooks/useCheckLicenseLimits';
//Dev Components
import DialogBox from './DialogBox';
//Images
import dropFiles from '../images/dropFiles.png';
import btnFechar from '../images/btnFechar.png';
//firebase
import { useFetchDocument } from '../hooks/useFetchDocument';
import { useUpdateDocument } from '../hooks/useUpdateDocument';

const ChooseFile = ({ handleChangeProjectSelected, changeProject, domainPlan, projectType, dataStorageAvaliable, totalStorage }) => {

  const { preferenceLanguage } = useLanguageValue();
  const { t } = useTranslation(['choosefile']);

  const { user } = useAuthValue();
  const { convertPPt, pptSize, errorConvertingPptImage } = useConvertPPTtoImage();
  const { convertPpttoText, errorConvertingPptText } = useConvertPPTtoText();
  const { convertVideoToText, extractAudioFromVideo, errorConvertingVideoText } = useConvertVideoToText();
  const { createProject } = useCreateProject();
  const { handleOpenAiApi, returnGPT, textTranslated, loading, errorGPT, generateGPT } = useAskChatGPT();

  const [userId, setUserId] = useState(user.uid)
  const [msgSub, setMsgSub] = useState("");
  const [idProject, setIdProject] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [projectSize, setProjectSize] = useState(0);
  const [fileExtention, setFileExtention] = useState(".ppt, .pptx, .mp4");
  const [isDragging, setIsDragging] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [msgDialog, setMsgDialog] = useState("");
  const [optionsDialog, setOptionsDialog] = useState(["OK"]);
  const [ableButtonGo, setAbleButtonGo] = useState(false);

  const [uploadingFile, setUploadingFile] = useState(false)
  const [progresspercent, setProgresspercent] = useState(0);
  const [fileMessange, setFileMessange] = useState(``);
  const [fileTipMessange, setFileTipMessange] = useState(``);
  const [messageIndex, setMessageIndex] = useState(0);

  const [projectSelected, setProjectSelected] = useState("");
  const [pptFileName, setPptFileName] = useState();
  const [pptPath, setPptPath] = useState(null);
  const [pptxUrl, setPptxUrl] = useState(null);
  const [convertingFile, setConvertingFile] = useState(false);
  const [pathToSlides, setPathToSlides] = useState("");
  const [listUrlSlides, setListUrlSlides] = useState([]);
  const [storagePPtSize, setStoragePPtSize] = useState(0);
  const [projectPPt, setProjectPPt] = useState([]);
  const [videoFileName, setVideoFileName] = useState("");
  const [generatingVideo, setGeneratingVideo] = useState(false);
  const [videoPath, setVideoPath] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [storageVideoSize, setStorageVideoSize] = useState(0);
  const [videoLanguage, setVideoLanguage] = useState("");
  const [projectVideos, setProjectVideos] = useState([]);
  const [videoText, setVideoText] = useState("");
  const [slidesSize, setSlidesSize] = useState(0);
  const [projectCreated, setProjectCreated] = useState(false);

  const [translatingSubtitles, setTranslatingSubtitles] = useState(false);
  const [askingIA, setAskingIA] = useState(false);
  const [objectSubtitle, setObjectSubtitle] = useState({});
  const [error, setError] = useState("");
  const [languageSubtitle, setLanguageSubtitle] = useState("pt-BR");

  const [storageLimit, setStorageLimit] = useState(0);

  const [totaltime, setTotaltime] = useState(0);

  const [storageTotalSize, setStorageTotalSize] = useState(0);

  const [action, setAction] = useState("error");

  const tipMessages = [
    t('choosefile:home.arquivo sendo convertido', {
      filename: pptFileName ? pptFileName : videoFileName,
      interpolation: { prefix: '${', suffix: '}' }
    }),
    t('choosefile:tips.review subtitle'),
    t('choosefile:tips.editar projeto'),
    t('choosefile:tips.tamanho video')
  ];

  var listUrls = [];
  var menuItems = [];
  var projectLanguage = "";


  const fileInputRef = useRef(null);
  const initialPPtFileName = "my_ppt_quizai_control.pptx";
  const initialPPtFile = new File([], initialPPtFileName, { type: "application/vnd.openxmlformats-officedocument.presentationml.presentation" });
  const [pptxFile, setPptxFile] = useState(initialPPtFile);
  const initialVideoFileName = "my_video_quizai_control.mp4";
  const initialVideoFile = new File([], initialVideoFileName, { type: "application/vnd.openxmlformats-officedocument.presentationml.presentation" });
  const [videoFile, setVideoFile] = useState(initialVideoFile);
  const [selectedFile, setSelectedFile] = useState(initialPPtFile);

  const { document: domain, loadingDocDomain, errorDocDomain } = useFetchDocument("Domains", userId);
  const { updateDocument, responseUpdate } = useUpdateDocument("Domains", user.uid);


  const handleCancel = () => {
    handleChangeProjectSelected("cancel")
  }

  const eventSelectFile = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    if (file) {
      const fileExtension = file.name.slice(file.name.lastIndexOf('.'), file.name.lastIndexOf('.') + 4).toLowerCase();

      if (projectType === 'subtitle') {
        if (fileExtension !== '.mp4') {
          const msg = t('choosefile:ChooseFile.arquivo não suportado para legendas');
          setMsgDialog(msg);
          setOptionsDialog(["Ok"]);
          return;
        }
        setProjectSelected('video');
      } else {
        if (fileExtension === ".ppt" || fileExtension === ".pptx" || fileExtension === ".mp4") {
          if (fileExtension === '.ppt' || fileExtension === '.pptx') {
            setProjectSelected('powerpoint');
          } else if (fileExtension === '.mp4') {
            if (domainPlan === "free") {
              const msg = t('choosefile:messages.O plano Free não suporta conversões de vídeo');
              setMsgDialog(msg);
              setOptionsDialog(["Ok"]);
              return;
            }
            setProjectSelected('video');
          }
        } else {
          const msg = t('choosefile:ChooseFile.carregar PowerPoint ou vídeo');
          setMsgSub(msg);
        }
      }
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    setSelectedFile(file);

    if (file) {
      const fileExtension = file.name.slice(file.name.lastIndexOf('.'), file.name.lastIndexOf('.') + 4).toLowerCase();

      if (projectType === 'subtitle') {
        if (fileExtension !== '.mp4') {
          const msg = t('choosefile:ChooseFile.arquivo não suportado para legendas');
          setMsgDialog(msg);
          setOptionsDialog(["Ok"]);
          return;
        }
        setProjectSelected('video');
      } else {
        if (fileExtension === ".ppt" || fileExtension === ".pptx" || fileExtension === ".mp4") {
          if (fileExtension === '.ppt' || fileExtension === '.pptx') {
            setProjectSelected('powerpoint');
          } else if (fileExtension === '.mp4') {
            if (domainPlan === "free") {
              const msg = t('choosefile:messages.O plano Free não suporta conversões de vídeo');
              setMsgDialog(msg);
              setOptionsDialog(["Ok"]);
              return;
            }
            setProjectSelected('video');
          }
        } else {
          const msg = t('choosefile:ChooseFile.carregar PowerPoint ou vídeo');
          setMsgSub(msg);
        }
      }
    }
  };

  const handleFileChange = (file, project) => {

    if (file && file.name != pptxFile.name && file.name != videoFile.name) {

      const fileSizeInBytes = file.size;
      const fileSizeInKB = fileSizeInBytes / 1024;
      const fileSizeInMB = fileSizeInKB / 1024;

      if (fileSizeInMB > dataStorageAvaliable) {
        setOpenDialog(true)
        const storageTotalSizeGb = (storageTotalSize / 1000).toFixed(1);
        const message = t('choosefile:common.storageSize', {
          storageTotalSizeMb: totalStorage.toFixed(1),
          fileSizeInMB: fileSizeInMB.toFixed(1),
          limitSize: storageLimit,
          interpolation: { prefix: '${', suffix: '}' }
        });

        setMsgDialog(message)
        setOptionsDialog(["Ok"])
        return
      }

      const filename = file.name;
      const nameWithoutExtension = filename.split('.')[0];

      setProjectName(nameWithoutExtension);

      let fileMaxSize = 0;
      let msgExtraSize = "";
      let folderFile = "";

      setSelectedFile(file);

      if (project === "powerpoint") {
        fileMaxSize = 20;
        msgExtraSize = (t('choosefile:common.excedeu 20Mb'))
        setPptFileName(filename);
        setPptxFile(file);
        folderFile = "ppt";
      } else if (project === "video") {
        fileMaxSize = 200;
        msgExtraSize = (t('choosefile:common.excedeu 200Mb'))
        setVideoFileName(filename);
        setVideoFile(file);
        setGeneratingVideo(true);
        folderFile = "video";
      }

      if (fileSizeInMB > fileMaxSize) {
        setOpenDialog(true)
        setMsgDialog(msgExtraSize)
        setOptionsDialog(["Ok"])
        return
      }

      setProjectSize(fileSizeInMB);

      if (!idProject) {
        const id = nameWithoutExtension + Timestamp.now();
        setIdProject(id);
        uploadFileToStorage(file, id, folderFile, filename);
      } else {
        deleteFolderContents(storage, `${folderFile}/${userId}/${idProject}`)
          .then(() => {
            uploadFileToStorage(file, idProject, folderFile, filename);
          })
          .catch((error) => {
            const message = t('choosefile:ChooseFile.Erro ao excluir conteúdo' + error);
            setAction("error")
            setMsgDialog(message);
          });
      }
    } else {
      return
    }
  };

  const uploadFileToStorage = async (file, id, folderFile, filename) => {
    setUploadingFile(true)

    const message = (t('choosefile:home.arquivo sendo carregado', {
      filename: filename,
      interpolation: { prefix: '${', suffix: '}' }
    }));

    setFileMessange(message)
    const bucketName = 'quizzai-4b3cd.appspot.com';
    try {
      if (file) {

        const storageRef = ref(storage, `${folderFile}/${userId}/${id}/${file.name}`);

        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed",
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgresspercent(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            const storagePath = uploadTask.snapshot.ref.fullPath;
            if (storagePath.startsWith('video')) {
              setVideoPath(`gs://${bucketName}/${storagePath}`);
            } else if (storagePath.startsWith('ppt')) {
              setPptPath(`gs://${bucketName}/${storagePath}`);
            }
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              projectSelected === "powerpoint" && setPptxUrl(downloadURL)
              projectSelected === "video" && setVideoUrl(downloadURL)
              setUploadingFile(false)
              setConvertingFile(true)
              const message = (t('choosefile:home.arquivo sendo convertido', {
                filename: filename,
                interpolation: { prefix: '${', suffix: '}' }
              }));
              setFileTipMessange(message)
            });
          }
        );
      } else {
        console.error('Nenhum arquivo selecionado.');
      }
    } catch (error) {
      setAction("error")
      const msg = (t('choosefile:ChooseFile.erro upload'))
      setMsgDialog(msg);
    }
  }

  const deletePpt = (pptPath) => {
    const fileRef = ref(storage, `${pptPath}`);
    const deletePromises = deleteObject(fileRef);
  }

  const deleteFolderContents = async (storage, path) => {
    setUploadingFile(true);
    const folderRef = ref(storage, path);
    const files = await listAll(folderRef);

    const deletePromises = files.items.map((item) => deleteObject(item));

    return Promise.all(deletePromises);
  };

  const loadPptImages = async (path) => {
    const checkUrls = async () => {
      try {
        const listRef = ref(storage, path);
        const items = await listAll(listRef);
        const imageUrls = await Promise.all(items.items.map(async (item) => {
          const itemName = item.name;
          if (itemName.startsWith('slide') && itemName.endsWith('.png')) {
            const imageUrl = await getDownloadURL(item);
            return imageUrl;
          }
          return null;
        }));
        const filteredImageUrls = imageUrls.filter((imageUrl) => imageUrl !== null);
        return filteredImageUrls;
      } catch (error) {
        setAction("error")
        setMsgDialog(error)
      }
    }
    listUrls = await checkUrls()
    changeMenuItems(listUrls.length)
    setListUrlSlides(listUrls)
  }

  const createFBProject = async (text, subtitle) => {

    const id = idProject ? idProject : projectName + Timestamp.now();
    setIdProject(id);

    const projectInfos = {
      actionDoc: "insert",
      idProject: id,
      introAssessment: "",
      contentGenerated: "",
      projectName: projectName,
      projectDescription: "",
      projectContent: "",
      includeContent: false,
      includeCase: false,
      includeSlides: projectSelected === "powerpoint" ? true : false,
      presentTogether: projectSelected === "powerpoint" ? true : false,
      presentVideoTogether: projectSelected === "video" ? true : false,
      projectFolder: "",
      assessment: {},
      language: projectLanguage,
      subject: "",
      pathToSlides: pathToSlides,
      videoUrl: videoUrl,
      videoPath: videoPath,
      includeVideo: projectSelected === "video" ? true : false,
      textPpt: projectSelected === "powerpoint" ? text : "",
      videoTranscript: projectSelected === "video" ? text : "",
      subtitleLanguage: languageSubtitle ? languageSubtitle : videoLanguage || "",
      subtitle: subtitle || "",
      listUrlSlides: listUrls,
      show_progress: true,
      menuItems: menuItems,
      showProgress: projectSelected === "powerpoint" ? true : false,
      menu_free_navigation: false,
      template_id: "quizai",
      projectFolder: "Folder",
      projectSize: projectSize
    }
    const res = createProject(projectInfos);
    
  }

  const handleConvertPpt = async (url) => {
    await convertPPt(url)
    const pathToFile = `ppt/${userId}/${idProject}`;
    setPathToSlides(pathToFile);
    const pptText = await convertPpttoText(url)
    checkLanguage(pptText)
    await loadPptImages(pathToFile);
    await createFBProject(pptText)
    setProjectCreated(true)
    setConvertingFile(false)
    setAbleButtonGo(true)
    deletePpt(pptPath)
  }

  const handleConvertVideo = async (url, size) => {
    const videoReturn = await convertVideoToText(url, videoLanguage)
    if (!videoReturn || !videoReturn.videoTxt) {
      return
    }
    const videoText = (!videoReturn || !videoReturn.videoTxt) ? "" : videoReturn.videoTxt;
    setVideoText(videoText)
    const objectVideoSub = (!videoReturn || !videoReturn.subtitlesGrouped) ? [] : videoReturn.subtitlesGrouped;
    handleUpdateProjectSize(projectSelected, size)
    setObjectSubtitle(objectVideoSub)

    checkLanguage(videoText)
    setConvertingFile(false)
    setAbleButtonGo(true)

    if (projectType != 'subtitle') {
      createFBProject(videoText, objectVideoSub)
      handleGoPreview()
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleGoPreview = () => {
    changeProject(idProject);
  }

  const actionScript = () => {
    setOpenDialog(false)
    setUploadingFile(false)
    setConvertingFile(false)
    setAbleButtonGo(false)
    setMsgDialog("")
  }

  const generateSubtitles = async () => {
    setOpenDialog(false)
    setUploadingFile(false)
    setAbleButtonGo(false)
    setMsgDialog("")
    await handleConvertVideo(videoPath, projectSize)
  }

  const changeSubtitleLanguage = async (origem, destiny) => {
    setVideoLanguage(origem);
    setLanguageSubtitle(destiny);
  }

  const changeMenuItems = (totalSlides) => {
    if (totalSlides) {
      const newMenuItems = [];
      for (let index = 0; index < totalSlides; index++) {
        const menuItemText = 'Slide ' + (index + 1);
        newMenuItems.push(menuItemText);
      }
      menuItems = newMenuItems;
    }
  }

  const handleUpdateProjectSize = (fileType, fileSizeInMb) => {
    let newStoragePPtSize = storagePPtSize;
    let newStorageVideoSize = storageVideoSize;

    if (fileType === "powerpoint") {
      newStoragePPtSize += fileSizeInMb;
      setStoragePPtSize(newStoragePPtSize);
    } else if (fileType === "video") {
      newStorageVideoSize += fileSizeInMb;
      setStorageVideoSize(newStorageVideoSize);
    }
    updateVideoPptProjects(newStorageVideoSize, newStoragePPtSize)
    
  };

  const updateVideoPptProjects = (videosize, pptsize) => {

    let userInfos = {
      domain: userId,
      ppt_storage_size: pptsize,
      video_storage_size: videosize,
    };

    const newProject = {
      creation_date: Timestamp.now(),
      project_name: idProject
    };

    if (projectSelected === "powerpoint") {
      const newProjectPPt = { ...projectPPt, [idProject]: newProject }
      userInfos.ppt_projects = newProjectPPt;
    } else if (projectSelected === "video") {
      const newProjectVideos = { ...projectVideos, [idProject]: newProject }
      userInfos.video_projects = newProjectVideos;

    }
    const resDoc = updateDocument(userInfos);
  };

  const updateProjectPptSize = (id, pptsize) => {
    console.log("slidesSize que interessa", slidesSize)

    const pptProjectInfos = {
      domain: userId,
      id: id,
      project_size: pptsize
    }

    updateDocument(pptProjectInfos)
    setProjectCreated(false)
    handleGoPreview()
  }

  const checkLanguage = (text) => {
    const langCode = franc(text);

    if (langCode === 'und') {
      if (preferenceLanguage) {
        switch (preferenceLanguage) {
          case "Português":
            projectLanguage = "Portuguese"
          case "Espanhol":
            projectLanguage = "Spanish"
          case "Inglês":
            projectLanguage = "Portuguese"
        }
      }
    } else {

      switch (langCode) {
        case "eng":
          projectLanguage = "English"
          break;
        case "spa":
          projectLanguage = "Spanish"
          break;
        case "por":
          projectLanguage = "Portuguese"
          break;
        case "ita":
          projectLanguage = "Italian"
          break;
        case "fra":
          projectLanguage = "French"
          break;
        case "deu":
          projectLanguage = "German"
          break;
        case "rus":
          projectLanguage = "Russian"
          break;
        case "jpn":
          projectLanguage = "Japanese"
          break;
        case "kor":
          projectLanguage = "Korean"
          break;
        case "cmn":
          projectLanguage = "Mandarin"
          break;
      }
    }
  }

  const changeLanguage = (language) => {
    localStorage.setItem('preferenceLanguage', language);
    i18n.changeLanguage(language);
  };

  const handleAskTranslateGPT = async () => {
    setConvertingFile(true)
    setFileTipMessange(t('choosefile:home.traduzindo legendas'))
    setTranslatingSubtitles(true)
    setAskingIA(true);

    const extractWords = (subtitles) => {
      return subtitles.map(subtitle => subtitle.words);
    };
    const wordsToTranslate = extractWords(objectSubtitle);

    try {
      const res = await handleOpenAiApi({
        objectSubtitle: wordsToTranslate,
        language: languageSubtitle,
        orderGPT: "translateSubtitle",
      });

    } catch (error) {
      setError(error);
      setTranslatingSubtitles(false);
    }
  }

  const cleanWords = (words) => {
    return words.replace(/["\n\[\]]/g, '').trim();
  };

  const cleanSubtitles = (subtitles) => {
    return subtitles.map(subtitle => ({
      ...subtitle,
      words: cleanWords(subtitle.words)
    }));
  };

  const updateObjectSubtitle = (subtitles, translatedText) => {

    const cleanedTranslatedWords = translatedText
      .replace(/^\n/, '')
      .replace(/\n$/, '')
      .split('\n')
      .map(word => word.trim())
      .filter(word => word !== "");

    if (cleanedTranslatedWords[0] === "[") {
      cleanedTranslatedWords.shift();
    }

    return subtitles.map((subtitle, index) => {
      const translatedWord = cleanedTranslatedWords[index];
      if (translatedWord && translatedWord.trim() !== "") {
        return {
          ...subtitle,
          words: translatedWord
        };
      }
      return subtitle;
    });
  };

  const saveSubtitles = (subtitle) => {
    checkLanguage(subtitle)
    const testelegendas = updateObjectSubtitle(objectSubtitle, subtitle)
    const cleanedLegendas = cleanSubtitles(testelegendas);
    setObjectSubtitle(cleanedLegendas)
    createFBProject(videoText, cleanedLegendas)
    handleGoPreview()
  }

  useEffect(() => {
    if (returnGPT) {
      saveSubtitles(returnGPT)
    }
  }, [returnGPT])

  useEffect(() => {
    if (videoLanguage && languageSubtitle && objectSubtitle && objectSubtitle.length > 0 && videoLanguage != languageSubtitle) {
      handleAskTranslateGPT();
    } else if (videoLanguage && languageSubtitle && objectSubtitle && objectSubtitle.length > 0 && videoLanguage === languageSubtitle) {
      createFBProject(videoText, objectSubtitle)
      handleGoPreview()
    }
  }, [objectSubtitle, videoLanguage, languageSubtitle])

  useEffect(() => {
    if (preferenceLanguage) {
      var language = "en";
      switch (preferenceLanguage) {
        case "Português":
          language = "pt"
          break;
        case "Espanhol":
          language = "es"
          break;
        case "Inglês":
          language = "en"
          break;
      }
      changeLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [preferenceLanguage])

  useEffect(() => {
    if (pptSize && pptSize > 0) {
      setProjectSize(pptSize)
      setSlidesSize(pptSize)
      handleUpdateProjectSize(projectSelected, pptSize)
    }
  }, [pptSize])

  useEffect(() => {
    console.log("useEffect disparado com projectCreated:", projectCreated);
    if (projectCreated) {
      console.log("entrou aqui");
      updateProjectPptSize(idProject, slidesSize);
    }
  }, [projectCreated]);

  useEffect(() => {
    if (pptxUrl) {
      handleConvertPpt(pptxUrl)
    }
  }, [pptxUrl])

  useEffect(() => {
    if (videoPath && videoUrl) {
      if (projectType === 'subtitle') {
        setFileExtention(".mp4")
        setAction("change_subtitle")
        setOpenDialog(true)
      } else {
        setAction("video_convert")
        setOpenDialog(true)
        //handleConvertVideo(videoPath, projectSize)
      }
    }
  }, [videoPath, videoUrl])

  useEffect(() => {
    if (errorConvertingPptImage) {
      setAction("error")
      setMsgDialog(errorConvertingPptImage)
    }
  }, [errorConvertingPptImage])

  useEffect(() => {
    if (errorConvertingPptText) {
      setAction("error")
      setMsgDialog(errorConvertingPptText)
    }
  }, [errorConvertingPptText])

  useEffect(() => {
    if (errorConvertingVideoText) {
      setAction("error")
      setMsgDialog(errorConvertingVideoText)
    }
  }, [errorConvertingVideoText])

  useEffect(() => {
    if (msgDialog) {
      setOpenDialog(true)
    }
  }, [msgDialog])

  useEffect(() => {
    if (selectedFile && projectSelected) {
      handleFileChange(selectedFile, projectSelected);
    }
  }, [selectedFile, projectSelected])

  useEffect(() => {
    if (domain) {
      setProjectPPt(domain.ppt_projects || [])
      setProjectVideos(domain.video_projects || [])
      setStoragePPtSize(domain.ppt_storage_size || 0)
      setStorageVideoSize(domain.video_storage_size || 0)
      setStorageLimit(domain.data_storage_limit)
    }
  }, [domain])

  useEffect(() => {
    if (translatingSubtitles) {
      tipMessages.splice(1, 0, t('choosefile:home.traduzindo legendas'));
    }
  }, [translatingSubtitles])

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % tipMessages.length);
      setFileTipMessange(tipMessages[messageIndex]);
    }, 20000);

    return () => clearInterval(interval);
  }, [messageIndex]);

  return (
    <div className="container m-0 p-0">
      <div className={`row ${styles.div_choosefile}`}>
        <div className={`col-12 position-relative ${styles.boxChooseFile} ${isDragging ? styles.dragOverBox : ''}`}
          onDragOver={(e) => handleDragOver(e)}
          onDragLeave={() => handleDragLeave()}
          onDrop={(e) => handleDrop(e)}>
          {(!uploadingFile && !convertingFile) &&
            <div className={`${styles.botao_fechar}`}>
              <a onClick={() => handleCancel()} ><img src={btnFechar} alt="btnFechar" /></a>
            </div>}
          {uploadingFile
            ? <div className='d-flex align-items-center justify-content-center flex-column'>
              <div className={`col-6 text-center`}>
                <div className={`progress ${styles.progressContainer}`} role="progressbar" aria-label="Info striped example" aria-valuenow={progresspercent} aria-valuemin="0" aria-valuemax="100">
                  <div className={`progress-bar progress-bar-striped bg-info`} style={{ width: `${progresspercent}%` }}></div>
                </div>
              </div>
              <h5 className={`d-flex justify-content-center mt-3 col-9 ${styles.subTitle}`}>
                {`${fileMessange}`}
              </h5>
            </div>
            : convertingFile
              ? <div className='d-flex align-items-center justify-content-center flex-column'>
                <div className={`col-6 text-center mt-5`}>
                  {
                    <div>
                      <div className="spinner-grow text-light" role="status"></div>
                      <h5 className={`mt-3 `}>
                        {`${fileTipMessange}`}
                      </h5>
                    </div>}
                </div>
              </div>
              : ableButtonGo
                ? <div className={`${styles.boxConverting}`}>
                  <h3 className={`${styles.successMessage}`}>
                    {t('choosefile:ChooseFile.Selecione.Seu arquivo foi convertido com sucesso')}
                  </h3>
                </div>
                :
                <div
                  className={`text-center mt-5`}>
                  <img src={dropFiles} alt="dropFiles" />
                  <h5 className={`col-auto mt-3 ${styles.subTitle}`}>{msgSub}</h5>
                  <div className="col-auto me-1">
                    <input type="file" ref={fileInputRef} onChange={(e) => eventSelectFile(e)} accept={fileExtention} className='d-none' />
                    <p>
                      <h4 className={`${styles.text_file}`}>
                        <a className={`${styles.mouseOverSelecione}`} onClick={() => fileInputRef.current.click()}>{t('choosefile:home.Selecione')}</a> {t('choosefile:home.o arquivo, ou o arraste para cá')} <span className={`${styles.text_extension}`}>{projectType === "file" ? t('choosefile:home.arquivos suportados') : t('choosefile:home.arquivos suportados subtitle')} </span>
                      </h4>
                    </p>
                  </div>
                </div>}
        </div>
        <br />
        <div>
          {(openDialog && action) && <DialogBox
            isopen={true}
            questionDialog={msgDialog}
            optionsDialog={optionsDialog}
            action={action}
            actionScript={(action === 'change_subtitle' || action === 'video_convert') ? generateSubtitles : actionScript}
            changeSubtitleLanguage={changeSubtitleLanguage}
          />}
        </div>
      </div>
    </div>

  )
}

export default ChooseFile